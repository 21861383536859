import React, { useState, useEffect } from "react";
import { Popover, PopoverSurface, useRestoreFocusTarget } from "@fluentui/react-components";
import IconButton from "@mui/material/IconButton";
import { ChevronDownRegular } from "@fluentui/react-icons";
import { Select, Input, Checkbox, Button } from "@fluentui/react-components";
import { fetchFolders, filterMessagesWithParameters, fetchParentFolderId } from "../../ApiHelpers";
import "../../styles/Filter.css";
import Loader from "../loader";

const FilterField = ({ label, children }) => (
  <div style={fieldContainerStyle}>
    <p style={labelStyle}>{label}</p>
    {children}
  </div>
);

const fieldContainerStyle = {
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "7px",
};

const labelStyle = {
  marginTop: "8px",
  marginBottom: 0,
};

const dateFieldContainerStyle = {
  display: "flex",
  marginBottom: "7px",
};

export default function Filter({ setAllMails }) {
  const [folders, setFolders] = useState([]);
  const [selectedFolderId, setSelectedFolderId] = useState("");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [cc, setCc] = useState("");
  const [subject, setSubject] = useState("");
  const [keywords, setKeywords] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [readStatus, setReadStatus] = useState("");
  const [attachments, setAttachments] = useState(false);
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = React.useState(false);
  const restoreFocusTargetAttribute = useRestoreFocusTarget();

  useEffect(() => {
    // Fetch folders on component mount
    const loadFolders = async () => {
      try {
        const folderList = await fetchFolders();

        setFolders(folderList);
      } catch (error) {
        console.error("Error loading folders:", error);
      }
    };

    loadFolders();
  }, []);

  const handleSearch = () => {
    setLoader(true);
    const filters = {
      selectedFolderId,
      from,
      to,
      cc,
      subject,
      keywords,
      dateFrom: dateFrom ? new Date(dateFrom) : null,
      dateTo: dateTo ? new Date(dateTo) : null,
      readStatus: readStatus === "Read" ? true : readStatus === "Unread" ? false : undefined,
      attachments,
    };

    filterMessagesWithParameters(filters)
      .then((messages) => {
        console.log("Filtered messages:", messages);
        setAllMails(messages);
        setLoader(false);
        setOpen(false);
        clearFilters();
      })
      .catch((error) => {
        console.error("Error fetching messages:", error);
        setLoader(false);
        setOpen(false);
        clearFilters();
      });
  };
  const handleTogglePopover = async () => {
    if (!open) {
      const currentFolderId = await fetchParentFolderId();
      setSelectedFolderId(currentFolderId);
    }
    setOpen((prev) => !prev);
  };

  const clearFilters = () => {
    setSelectedFolderId("");
    setFrom("");
    setTo("");
    setCc("");
    setSubject("");
    setKeywords("");
    setDateFrom("");
    setDateTo("");
    setReadStatus("");
    setAttachments(false);
  };

  return (
    <div>
      <IconButton aria-label="filter" size="small" {...restoreFocusTargetAttribute} onClick={handleTogglePopover}>
        <ChevronDownRegular fontSize="inherit" />
      </IconButton>
      <Popover onOpenChange={(_, data) => setOpen(data.open)} trapFocus open={open}>
        <PopoverSurface tabIndex={-1} style={{ width: "300px" }} className="PopoverSurface">
          {loader && <Loader />}

          <FilterField label="Search in">
            <Select
              appearance="underline"
              style={{ width: "195px" }}
              value={selectedFolderId}
              onChange={(e) => setSelectedFolderId(e.target.value)}
            >
              {folders.map((folder) => (
                <option key={folder.Id} value={folder.Id}>
                  {folder.DisplayName}
                </option>
              ))}
            </Select>
          </FilterField>

          <FilterField label="From">
            <Input
              appearance="underline"
              style={{ width: "195px" }}
              value={from}
              onChange={(e) => setFrom(e.target.value)}
            />
          </FilterField>

          <FilterField label="To">
            <Input
              appearance="underline"
              style={{ width: "195px" }}
              value={to}
              onChange={(e) => setTo(e.target.value)}
            />
          </FilterField>

          <FilterField label="CC">
            <Input
              appearance="underline"
              style={{ width: "195px" }}
              value={cc}
              onChange={(e) => setCc(e.target.value)}
            />
          </FilterField>

          <FilterField label="Subject">
            <Input
              appearance="underline"
              style={{ width: "195px" }}
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
          </FilterField>

          <FilterField label="Keywords">
            <Input
              appearance="underline"
              style={{ width: "195px" }}
              value={keywords}
              onChange={(e) => setKeywords(e.target.value)}
            />
          </FilterField>

          <div style={dateFieldContainerStyle}>
            <div className="date-container">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "7px",
                  width: "100%",
                }}
              >
                <p style={{ ...labelStyle, marginRight: "15px" }}>Date from</p>
                <input
                  type="date"
                  name="dateStart"
                  id="dateStart"
                  className="date-input"
                  value={dateFrom}
                  onChange={(e) => setDateFrom(e.target.value)}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "7px",
                  width: "100%",
                }}
              >
                <p style={{ ...labelStyle, marginRight: "15px" }}>Date to</p>
                <input
                  type="date"
                  name="dateEnd"
                  id="dateEnd"
                  className="date-input"
                  value={dateTo}
                  onChange={(e) => setDateTo(e.target.value)}
                />
              </div>
            </div>
          </div>

          <FilterField label="Read status">
            <Select
              appearance="underline"
              style={{ width: "195px" }}
              value={readStatus}
              onChange={(e) => setReadStatus(e.target.value)}
            >
              <option value="">Select status</option>
              <option value="read">Read</option>
              <option value="unread">Unread</option>
            </Select>
          </FilterField>

          <div style={{ display: "flex" }}>
            <p style={{ marginTop: "5px", marginBottom: 0, marginRight: "20px" }}>Attachments</p>
            <Checkbox checked={attachments} onChange={(e) => setAttachments(e.target.checked)} />
          </div>

          <div style={{ display: "flex", justifyContent: "end", margin: "6px", marginTop: "12px", gap: "5px" }}>
            <Button onClick={clearFilters}>Clear filters</Button>
            <Button appearance="primary" onClick={handleSearch}>
              Search
            </Button>
          </div>
        </PopoverSurface>
      </Popover>
    </div>
  );
}
