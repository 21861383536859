import React, { useState, useEffect } from "react";
import Search from "../../components/search";
import ContentCards from "../../components/home-content-card";
import { Checkbox } from "@fluentui/react-components";
import SaveEmail from "../../components/save-email-btn";
import { fetchConversationMessages } from "../../ApiHelpers";
import Loader from "../../components/loader";
import "./index.css";
const Home = () => {
  const [selectedMails, setSelectedMails] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [allMails, setAllMails] = useState([]);
  const [loader, setLoader] = useState(false);

  // Fetch messages based on search text
  const fetchMessages = async (searchText) => {
    setLoader(true);
    try {
      const result = await fetchConversationMessages(searchText);
      setAllMails(result);
    } catch (error) {
      console.error("Error fetching messages:", error);
    } finally {
      setLoader(false);
    }
  };

  // Call fetchMessages on component mount or search text change
  useEffect(() => {
    if (searchText == "") {
      fetchMessages(searchText);
    }
  }, [searchText]);

  useEffect(() => {
    setSelectedMails([]);
  }, [allMails]);

  // Handle "Select All" checkbox change
  const handleSelectAllChange = (event) => {
    const isChecked = event.target.checked;
    const newSelectedMails = isChecked ? [...allMails] : [];
    setSelectedMails(newSelectedMails);
  };

  // Check if all cards are selected
  const allSelected = allMails.length > 0 && selectedMails.length === allMails.length;

  // Handle individual card checkbox change
  const handleCardCheckboxChange = (mail) => {
    setSelectedMails((prevSelectedMails) => {
      const updatedSelectedMails = [...prevSelectedMails];
      const mailIndex = updatedSelectedMails.findIndex((selectedMail) => selectedMail.Id === mail.Id);
      if (mailIndex > -1) {
        updatedSelectedMails.splice(mailIndex, 1);
      } else {
        updatedSelectedMails.push(mail);
      }
      return updatedSelectedMails;
    });
  };

  const handleSearch = () => {
    fetchMessages(searchText);
  };

  return (
    <div className="container">
      {loader && <Loader />}

      <div style={{ display: "flex", justifyContent: "center" }}>
        <Search setAllMails={setAllMails} setSearchText={setSearchText} handleSearch={handleSearch} />
      </div>

      <div style={{ marginTop: "20px" }}>
        <Checkbox label="Select All" checked={allSelected} onChange={handleSelectAllChange} />
      </div>

      <div style={{ overflowY: "auto", height: "calc(100vh - 90px)" }}>
        {allMails.map((card) => (
          <ContentCards
            key={card.Id}
            title={card.Subject}
            content={card.BodyPreview}
            isChecked={selectedMails.some((selectedMail) => selectedMail.Id === card.Id)}
            onCheckboxChange={() => handleCardCheckboxChange(card)}
          />
        ))}
      </div>

      <div>
        <SaveEmail selectedMails={selectedMails} setLoader={setLoader} setSelectedMails={setSelectedMails} />
      </div>
    </div>
  );
};

export default Home;
