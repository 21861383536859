import React from "react";
import { Text, Caption1, Checkbox, Card } from "@fluentui/react-components";

export default function ContentCards({ title, content, isChecked, onCheckboxChange }) {
  return (
    <div
      style={{
        margin: "10px",
      }}
    >
      <Card
        appearance="outline"
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Checkbox checked={isChecked} onChange={onCheckboxChange} />
        <label style={{ marginLeft: "10px" }}>
          <Text block weight="semibold">
            {title === "" ? "No email Subject !" : title}{" "}
          </Text>
          <Caption1 block>{content === "" ? "No email Content!" : content} </Caption1>
        </label>
      </Card>
    </div>
  );
}
