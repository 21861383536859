export async function fetchConversationMessages(searchText) {
  return new Promise((resolve, reject) => {
    Office.context.mailbox.getCallbackTokenAsync({ isRest: true }, async function (result) {
      if (result.status === "succeeded") {
        const accessToken = result.value;

        try {
          let url = "";
          if (searchText !== "") {
            url = `https://outlook.office.com/api/v2.0/me/messages?$search="${searchText}"&$top=1000`; // Default URL for the first 1000 messages
          } else {
            url = `https://outlook.office.com/api/v2.0/me/messages?$top=100`; // Default URL for the first 1000 messages
          }
          // Step 1: Fetch the list of messages
          const messagesResponse = await fetch(url, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
          });

          if (!messagesResponse.ok) {
            return reject(`Failed to fetch messages: ${messagesResponse.statusText}`);
          }

          const messagesData = await messagesResponse.json();

          // Step 2: Fetch attachments for each message if HasAttachments is true
          const messagesWithAttachments = await Promise.all(
            messagesData.value.map(async (message) => {
              if (message.HasAttachments) {
                // If the message has attachments, fetch them
                const attachmentsResponse = await fetch(
                  `https://outlook.office.com/api/v2.0/me/messages/${message.Id}/attachments`,
                  {
                    method: "GET",
                    headers: {
                      Authorization: `Bearer ${accessToken}`,
                      "Content-Type": "application/json",
                    },
                  }
                );

                if (!attachmentsResponse.ok) {
                  throw new Error(
                    `Failed to fetch attachments for message ${message.Id}: ${attachmentsResponse.statusText}`
                  );
                }

                const attachmentsData = await attachmentsResponse.json();

                // Add attachments to the message object
                message.attachments = attachmentsData.value.map((attachment) => {
                  if (attachment["@odata.type"] === "#Microsoft.OutlookServices.FileAttachment") {
                    return {
                      name: attachment.Name,
                      contentType: attachment.ContentType,
                      size: attachment.Size,
                      contentBytes: attachment.ContentBytes, // Base64 encoded content
                    };
                  }
                  return attachment; // Return as-is if not a file attachment
                });
              } else {
                // If no attachments, set an empty array
                message.attachments = [];
              }

              return message; // Return the message with attachments array (empty or populated)
            })
          );

          resolve(messagesWithAttachments); // Resolve with the final array of messages
        } catch (error) {
          reject(`Error fetching messages or attachments: ${error.message}`);
        }
      } else {
        reject("Failed to get callback token");
      }
    });
  });
}

// Function to fetch folder information
export async function fetchFolders() {
  return new Promise((resolve, reject) => {
    Office.context.mailbox.getCallbackTokenAsync({ isRest: true }, async function (result) {
      if (result.status === "succeeded") {
        const accessToken = result.value;

        try {
          const response = await fetch("https://outlook.office.com/api/v2.0/me/mailFolders?$top=100", {
            method: "GET",
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
          });

          if (!response.ok) {
            throw new Error(`Failed to fetch folders: ${response.statusText}`);
          }

          const foldersData = await response.json();
          resolve(foldersData.value); // Resolve with an array of folder objects
        } catch (error) {
          reject(`Error fetching folders: ${error.message}`);
        }
      } else {
        reject("Failed to get callback token");
      }
    });
  });
}

export function filterMessagesWithParameters(filters) {
  return new Promise((resolve, reject) => {
    Office.context.mailbox.getCallbackTokenAsync({ isRest: true }, async function (result) {
      if (result.status !== "succeeded") {
        return reject("Failed to get callback token");
      }

      const accessToken = result.value;

      // Main function to fetch messages with or without folder filter
      const fetchMessages = async () => {
        try {
          let url = "https://outlook.office.com/api/v2.0/me/messages?$top=1000"; // Default URL for the first 1000 messages
          if (filters.subject) {
            url = `https://outlook.office.com/api/v2.0/me/messages?$search="${filters.subject.toLowerCase()}"&$top=1000`; // Default URL for the first 1000 messages
          }
          if (filters.keywords) {
            url = `https://outlook.office.com/api/v2.0/me/messages?$search="${filters.keywords.toLowerCase()}"&$top=1000`; // Default URL for the first 1000 messages
          }
          // If a folder ID is selected, change the URL to get messages from that folder
          if (filters.selectedFolderId) {
            url = `https://outlook.office.com/api/v2.0/me/messages?$filter=ParentFolderId eq '${filters.selectedFolderId}'&$top=1000`;
          }

          const response = await fetch(url, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
          });

          if (!response.ok) {
            throw new Error(`Failed to fetch initial messages: ${response.statusText}`);
          }

          let messagesData = await response.json();

          // Step 2: Apply filter conditions for exact matches
          let filteredMessages = messagesData.value;

          let urlForChild = "https://outlook.office.com/api/v2.0/me/messages?$top=1000"; // Default URL for the first 1000 messages
          if (filters.subject) {
            urlForChild = `https://outlook.office.com/api/v2.0/me/messages?$search="${filters.subject.toLowerCase()}"&$top=1000`; // Default URL for the first 1000 messages
          }
          if (filters.keywords) {
            urlForChild = `https://outlook.office.com/api/v2.0/me/messages?$search="${filters.keywords.toLowerCase()}"&$top=1000`; // Default URL for the first 1000 messages
          }
          const childFoldersUrl = `https://outlook.office.com/api/v2.0/me/mailFolders/${filters.selectedFolderId}/childFolders`;
          const childFoldersResponse = await fetch(childFoldersUrl, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
          });

          if (!childFoldersResponse.ok) {
            throw new Error(`Failed to fetch child folders: ${childFoldersResponse.statusText}`);
          }

          const childFoldersData = await childFoldersResponse.json();
          const childFolderIds = childFoldersData.value.map((folder) => folder.Id);
          if (childFolderIds.length > 0) {
            // Fetch messages from each child folder
            for (const childFolderId of childFolderIds) {
              urlForChild = `https://outlook.office.com/api/v2.0/me/messages?$filter=ParentFolderId eq '${childFolderId}'&$top=1000`; // Corrected the syntax error

              const childFolderUrl = urlForChild;
              const childFolderResponse = await fetch(childFolderUrl, {
                method: "GET",
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                  "Content-Type": "application/json",
                },
              });

              if (!childFolderResponse.ok) {
                throw new Error(`Failed to fetch child folder messages: ${childFolderResponse.statusText}`);
              }

              const childFolderMessagesData = await childFolderResponse.json();
              filteredMessages = filteredMessages.concat(childFolderMessagesData.value); // Merge child folder messages with parent folder messages
            }
          }

          // Apply filters locally
          if (filters.from) {
            const fromFilter = filters.from.toLowerCase(); // Convert filter to lowercase
            filteredMessages = filteredMessages.filter(
              (msg) => msg.From.EmailAddress.Address.toLowerCase().includes(fromFilter) // Case-insensitive comparison
            );
          }

          if (filters.to) {
            const toFilter = filters.to.toLowerCase(); // Convert filter to lowercase
            filteredMessages = filteredMessages.filter(
              (msg) => msg.ToRecipients.some((t) => t.EmailAddress.Address.toLowerCase().includes(toFilter)) // Case-insensitive
            );
          }

          if (filters.cc) {
            const ccFilter = filters.cc.toLowerCase(); // Convert filter to lowercase
            filteredMessages = filteredMessages.filter(
              (msg) => msg.CcRecipients.some((c) => c.EmailAddress.Address.toLowerCase().includes(ccFilter)) // Case-insensitive
            );
          }

          if (filters.subject) {
            const subjectFilter = filters.subject.toLowerCase(); // Convert filter to lowercase
            filteredMessages = filteredMessages.filter(
              (msg) => msg.Subject.toLowerCase().includes(subjectFilter) // Case-insensitive comparison
            );
          }

          if (filters.keywords) {
            const keywordFilter = filters.keywords.toLowerCase(); // Convert filter to lowercase
            filteredMessages = filteredMessages.filter(
              (msg) => msg.Body.Content.toLowerCase().includes(keywordFilter) // Case-insensitive comparison
            );
          }

          // Apply date range and read status filters
          if (filters.dateFrom) {
            filteredMessages = filteredMessages.filter((msg) => new Date(msg.ReceivedDateTime) >= filters.dateFrom);
          }
          if (filters.dateTo) {
            filteredMessages = filteredMessages.filter((msg) => new Date(msg.ReceivedDateTime) <= filters.dateTo);
          }
          if (filters.isRead !== undefined) {
            filteredMessages = filteredMessages.filter((msg) => msg.IsRead === filters.isRead);
          }

          // Step 3: Apply HasAttachments filter ONLY if the filter is true
          if (filters.attachments === true) {
            filteredMessages = filteredMessages.filter((msg) => msg.HasAttachments === true);
          }

          // Step 4: Fetch attachments for messages that have attachments
          const fetchAttachments = async (messageId) => {
            const attachmentUrl = `https://outlook.office.com/api/v2.0/me/messages/${messageId}/attachments`;
            const attachmentResponse = await fetch(attachmentUrl, {
              method: "GET",
              headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
              },
            });

            if (!attachmentResponse.ok) {
              throw new Error(
                `Failed to fetch attachments for message ID ${messageId}: ${attachmentResponse.statusText}`
              );
            }

            return await attachmentResponse.json();
          };

          // Step 5: Add attachments to filtered messages that have attachments
          for (let msg of filteredMessages) {
            if (msg.HasAttachments) {
              const attachments = await fetchAttachments(msg.Id);
              msg.attachments = attachments.value; // Add attachments to message
            }
          }

          // Resolve the filtered messages
          resolve(filteredMessages);
        } catch (error) {
          reject(`Error: ${error.message}`);
        }
      };

      // Start the fetch process
      fetchMessages();
    });
  });
}

export const fetchParentFolderId = async () => {
  return new Promise((resolve, reject) => {
    Office.onReady(() => {
      if (Office.context.mailbox) {
        Office.context.mailbox.getCallbackTokenAsync({ isRest: true }, async function (result) {
          if (result.status === "succeeded") {
            let accessToken = result.value;

            const itemId = Office.context.mailbox.item.itemId;
            const restId = Office.context.mailbox.convertToRestId(itemId, Office.MailboxEnums.RestVersion.v2_0);
            const url = `https://outlook.office.com/api/v2.0/me/messages/${restId}`;

            try {
              const response = await fetch(url, {
                method: "GET",
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                  "Content-Type": "application/json",
                },
              });

              if (!response.ok) {
                throw new Error(`Failed to fetch item: ${response.statusText}`);
              }

              const messageData = await response.json();
              const parentFolderId = messageData.ParentFolderId;
              console.log("Parent Folder ID:", parentFolderId);

              resolve(parentFolderId);
            } catch (error) {
              console.error("Error fetching parent folder ID:", error);
              reject(null);
            }
          }
        });
      } else {
        console.error("Mailbox not available.");
        reject(null);
      }
    });
  });
};
