import React, { useState, useEffect } from "react";
import { postAISummary } from "../../ApiHelpers/SummarizeContent";
import {
  Dialog,
  DialogSurface,
  Input,
  Label,
  DialogContent,
  DialogActions,
  DialogTrigger,
  DialogBody,
  Button,
  useRestoreFocusTarget,
  Checkbox,
} from "@fluentui/react-components";
import html2pdf from "html2pdf.js"; // Import html2pdf.js for PDF generation

export default function SaveEmail({ selectedMails, setSelectedMails, setLoader }) {
  const [open, setOpen] = useState(false);
  const [tagAll, setAllTag] = useState(false);
  const [isSummary, setIsSummary] = useState(true);

  const [prefix, setPrefix] = useState("");

  const [emailNames, setEmailNames] = useState([]);

  const restoreFocusTargetAttribute = useRestoreFocusTarget();

  // Convert selectedMails (Set) to an array and sync email names with selected mails
  useEffect(() => {
    setEmailNames(
      selectedMails.map((mail) => {
        // Extract Subject, ensure it's a valid string, and use it for the filename
        return mail.Subject ? `${mail.Subject}.pdf` : "Untitled.pdf";
      })
    );
  }, [selectedMails]);

  // Handle email name change
  const handleEmailNameChange = (index, newValue) => {
    const updatedNames = [...emailNames];
    updatedNames[index] = newValue;
    setEmailNames(updatedNames);
  };

  // Convert email content to PDF and download attachments
  const handleSave = async () => {
    let emailBody = ""; // Combine the content of all selected emails

    // Combine email content
    selectedMails.forEach((mail) => {
      if (mail.BodyPreview !== "") {
        emailBody += mail.Body.Content; // Accumulate email content
      }
    });

    // Show loader
    setLoader(true);
    setOpen(false); // Close dialog after saving

    try {
      // Step 1: Generate AI Summary
      if (isSummary) {
        const summaryResponse = await postAISummary(emailBody);

        // Step 2: Convert the AI summary to a PDF and download it
        const summaryPDFContent = `
        <div style="font-family: Arial, sans-serif; padding: 20px; max-width: 600px; margin: auto;">
          <h1 style="text-align: center; font-size: 1.5em;">AI Summary</h1>
          <hr />
          <div style="text-align: justify; line-height: 1.6;">${summaryResponse}</div>
        </div>
      `;
        await html2pdf()
          .from(summaryPDFContent)
          .set({ filename: `${prefix}_AISummary.pdf` })
          .save();
      }

      // Step 3: Download individual emails as PDFs
      for (const [index, mail] of selectedMails.entries()) {
        if (mail.BodyPreview !== "") {
          const emailContent = `
            <div style="font-family: Arial, sans-serif; padding: 20px; max-width: 600px; margin: auto;">
              <h1 style="text-align: center; font-size: 1.5em;">${mail.Subject}</h1>
              <hr />
              <h2 style="font-size: 1.2em; margin-top: 20px;">Content:</h2>
              <div style="text-align: justify; line-height: 1.6;">${mail.Body.Content}</div>
            </div>
          `;
          const emailName = emailNames[index];
          await html2pdf().from(emailContent).set({ filename: emailName }).save();
        }

        // Step 4: Download attachments
        if (mail.attachments && mail.attachments.length > 0) {
          mail.attachments.forEach((attachment) => {
            const contentBytes = attachment.ContentBytes;

            if (!contentBytes) {
              console.error("Attachment has no content bytes.");
              return;
            }

            try {
              const binaryString = atob(contentBytes.replace(/\s/g, ""));
              const binaryLen = binaryString.length;
              const bytes = new Uint8Array(binaryLen);

              for (let i = 0; i < binaryLen; i++) {
                bytes[i] = binaryString.charCodeAt(i);
              }

              const blob = new Blob([bytes], { type: attachment.ContentType });
              const url = URL.createObjectURL(blob);
              const link = document.createElement("a");
              link.href = url;
              link.download = attachment.Name;
              link.click();
              URL.revokeObjectURL(url);
            } catch (e) {
              console.error("Error while decoding attachment:", e);
            }
          });
        }
      }

      setLoader(false); // Hide loader on success
    } catch (error) {
      console.error("Error generating AI summary:", error);

      // Step 5: Handle failure case - Create a failure message as a PDF
      const failureMessageContent = `
        <div style="font-family: Arial, sans-serif; padding: 20px; max-width: 600px; margin: auto;">
          <h1 style="text-align: center; font-size: 1.5em; color: red;">AI Summary Failed</h1>
          <hr />
          <div style="text-align: justify; line-height: 1.6;">
            Unfortunately, we couldn't generate the AI summary. Please try again later.
          </div>
        </div>
      `;
      await html2pdf()
        .from(failureMessageContent)
        .set({ filename: `${prefix}_AISummary_Failed.pdf` })
        .save();

      setLoader(false); // Hide loader on failure
    }

    // Reset fields
    setSelectedMails([]);
    setEmailNames([]);
    setPrefix("");
    setAllTag(false);
  };

  const handleTagAll = () => {
    setAllTag(true);
  };

  const handlePrefix = (value) => {
    setPrefix(value);
    setEmailNames(emailNames.map((name) => `${value}_${name.split("_").pop()}`));
  };
  const handleSummaryStatus = (value) => {
    setIsSummary(value);
  };
  return (
    <>
      <Button
        appearance="primary"
        style={{
          position: "fixed",
          bottom: "10px",
          left: "50%",
          width: "90%",
          transform: "translateX(-50%)",
          marginInline: "auto",
        }}
        {...restoreFocusTargetAttribute}
        onClick={() => {
          setOpen(true);
        }}
      >
        Save Email
      </Button>

      <Dialog
        style={{
          margin: "10px",
        }}
        open={open}
        onOpenChange={(event, data) => {
          setOpen(data.open);
        }}
      >
        <DialogSurface>
          <DialogBody>
            <DialogContent>
              {Array.from(selectedMails).map((mail, index) => (
                <div key={index} style={{ marginBottom: "20px" }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Label>Email:</Label>
                    {mail.BodyPreview !== "" ? (
                      <Input
                        value={emailNames[index]}
                        onChange={(e) => handleEmailNameChange(index, e.target.value)}
                        appearance="underline"
                      />
                    ) : (
                      <div style={{ color: "gray" }}>No email content available</div>
                    )}
                  </div>
                  <div style={{ display: "flex", flexDirection: "column", marginTop: "10px" }}>
                    <Label>Email Attachments:</Label>
                    {mail.attachments && mail.attachments.length > 0 ? (
                      mail.attachments.map((attachment, attIndex) => (
                        <Input
                          key={attIndex}
                          value={prefix !== "" ? `${prefix}_${attachment.Name}` : attachment.Name}
                          appearance="underline"
                        />
                      ))
                    ) : (
                      <div style={{ color: "gray" }}>No attachments</div>
                    )}
                  </div>
                </div>
              ))}
            </DialogContent>
            <DialogActions
              style={{
                display: "flex",
                flexDirection: "column", // Arrange items in a column
                alignItems: "center", // Center content horizontally
                gap: "8px", // Add spacing between elements
              }}
            >
              {tagAll && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    marginLeft: "-40px",
                    borderTop: "1px solid black",
                    paddingTop: "8px",
                  }}
                >
                  <Label>Prefix:</Label>
                  <Input value={prefix} onChange={(e) => handlePrefix(e.target.value)} appearance="underline" />
                </div>
              )}

              {/* Checkbox */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  marginLeft: "-125px",
                }}
              >
                <Checkbox
                  label="Generate Summary"
                  checked={isSummary}
                  onChange={(e) => handleSummaryStatus(e.target.checked)}
                />
              </div>

              {/* Buttons */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "12px",
                  width: "100%",
                }}
              >
                <Button appearance="primary" onClick={handleTagAll}>
                  Tag All
                </Button>
                <DialogTrigger disableButtonEnhancement>
                  <Button appearance="secondary">Cancel</Button>
                </DialogTrigger>
                <Button appearance="primary" onClick={handleSave}>
                  Save
                </Button>
              </div>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    </>
  );
}
