import React, { useState } from "react";
import { Input } from "@fluentui/react-components";
import { SearchRegular } from "@fluentui/react-icons";
import "../../styles/Search.css";
import { searchMessages } from "../../ApiHelpers";
import Filter from "../filter";
const Search = ({ setAllMails, setSearchText, handleSearch }) => {
  const onSearchTextChane = (e) => {
    setSearchText(e.target.value);
  };

  const handlePressEnter = (e) => {
    if (e.key === "Enter") {
      handleSearch(); // Trigger search when Enter is pressed
    }
  };

  return (
    <div>
      <Input
        contentBefore={<SearchRegular />}
        placeholder="Search"
        className="search-input"
        onChange={onSearchTextChane}
        onKeyDown={handlePressEnter}
        contentAfter={<Filter setAllMails={setAllMails} />}
      />
    </div>
  );
};

export default Search;
