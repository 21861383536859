export function postAISummary(message) {
    return new Promise((resolve, reject) => {

        const requestData = {
            tenantId: "",
            clientId: "",
            clientSecret: "",
            redirectUrl: "",
            code: "",
            refreshToken:"" ,
            prompt: message,
            openAiKey: "",
            userEmail: "",
            userName: ""
        };
        

        fetch('https://www.api.logimail.site/api/User/AISummary', {
            method: 'POST',
            headers: {
                'accept': 'text/plain',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestData)
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.text();
        })
        .then(data => resolve(data))
        .catch(error => reject(error));
    });
}

